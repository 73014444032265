:where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark .ant-menu-item-selected,
 :where(.css-dev-only-do-not-override-pr0fja).ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #ddb42c !important;
 }


.ant-menu-light .ant-menu-item:hover {
  color: #ddb42c !important; 
}
.ant-menu-light .ant-menu-item-selected{
  /* background:transparent; */
  background: #ddb42c;
  color:#fff !important;
}

.ant-menu-light .ant-menu-item-selected:hover {
    color: #fff !important;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,.ant-tabs .ant-tabs-tab:hover {
    color:#1b2910;
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #1b2910;
  }

 .custom-btn{
  background-color: #1b2910 !important;
  color:#fff;
 }
 
 .ant-layout-sider-zero-width-trigger {
  top: 0 !important;
  background: #010001 !important;
}